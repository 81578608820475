<template>
  <v-app>
    <v-main class="back">
      <v-snackbar v-model="errors" color="error" top multi-line>
        Invalid Credentials or domain is not active
        <v-btn text @click="errors = false">
          Close
        </v-btn>
      </v-snackbar>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <base-material-card>
              <template v-slot:heading>
                <div class="display-2 text-center">
                  Sign in
                </div>
              </template>

              <v-form @submit="login()" onSubmit="return false;">
                <v-container class="">
                  <v-row>
                    <v-container>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          class="purple-input"
                          label="Username"
                          v-model="user.username"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="user.password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          name="input-10-1"
                          label="Password"
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        class="text-center"
                      >
                        <v-btn
                          color="success"
                          class="mr-0"
                          type="submit"

                        >
                          Sign in
                        </v-btn>
                      </v-col>
                    </v-container>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: "Login",
    created() {
      if(this.$route.query.token){
        this.loginByToken(this.$route.query.token)
      }
    },
    data () {
      return {
        user: {
          username: '',
          password: ''
        },
        showPassword: false,
        errors: false
      }
    },
    methods: {
      login() {
        this.$baseApi.post('auth/login', this.user).then(res => {
          this.setAuthInfo(res);
          window.location.href = '/' + this.$auth.getEnabledFeatures()[0];
        }).catch(error => {
          this.errors = true;
        })
      },
      loginByToken(token) {
        this.$baseApi.post('auth/login-by-token', {token}).then(res => {
          this.setAuthInfo(res);
          window.location.href = '/' + this.$auth.getEnabledFeatures()[0];
        }).catch(error => {
          this.errors = true;
        })
      },
      setAuthInfo (response) {
        let token = response.data.data.access_token;
        let expiration = Date.parse(response.data.data.expires_at);
        this.$auth.setToken(token, expiration);
      }
    },
    components: {}
  }
</script>

<style scoped>
  .back {
    background-image: url("https://demos.creative-tim.com/vuetify-material-dashboard-pro/img/login.d6d3bb09.jpg");
    background-size: cover;
  }
</style>
